<template>
<div>
  <account-role-title role="Admin" />
  <span class="deals-details-title">{{ $t("Product Details") }}</span>
  <v-row>
    <v-col md="9">
      <product-data></product-data>
      <product-statistics></product-statistics>
    </v-col>
    <v-col md="3" class="pl-0 pt-0">
      <order-details></order-details>
      <order-history></order-history>
    </v-col>
  </v-row>
</div>
</template>

<script>
import OrderDetails from "@/views/cabinet/order/order-view/OrderDetails";
import OrderHistory from "@/views/cabinet/order/order-view/OrderHistory";
import ProductData from "@/views/cabinet/order/order-view/ProductData";
import ProductStatistics from "@/views/cabinet/order/order-view/ProductStatistics";
import store from "@/store";
import router from "@/router";
import {useRouter} from "@core/utils";
import {watch} from "@vue/composition-api";

export default {
  name: "AdminOrder",
  components: {ProductStatistics, ProductData, OrderHistory, OrderDetails},
  setup() {
    const getProduct = () => {
      store.dispatch('order/getAdminOrder', {id: router.currentRoute.params.id})
    }
    getProduct()
    const {route} = useRouter()
    watch(route, () => {
      getProduct()
    })
  }
}
</script>
